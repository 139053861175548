<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="navMenuItemsFiltrados" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'
import {mapState} from "vuex";

export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    return {
      navMenuItems,
    }
  },
  computed: {
    ...mapState({
      laboratorio: state => state.laboratorios.configuracaoLaboSelecionado,
      user: state => state.user.user,
    }),
    navMenuItemsFiltrados() {
      let menu = [];

      const isAllowedForOwner = (item) => {
        return !this.laboratorio.odonto_financeiro && item.route !== 'financeiro';
      };

      const isAllowedForNonOwner = (item) => {
        return item.route !== 'financeiro' && item.route !== 'usuarios';
      };

      this.navMenuItems.forEach((navItem) => {
        if (this.user.owner) {
          if (isAllowedForOwner(navItem) || this.laboratorio.odonto_financeiro) {
            menu.push(navItem);
          }
        } else {
          if (isAllowedForNonOwner(navItem)) {
            menu.push(navItem);
          }
        }
      });

      return menu;
    }
  },

}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
